import { Navigation, Pagination } from 'swiper/modules';
import { SwiperSlide } from 'swiper/react';

import Image from 'next/image';

import NextNavigationButton from 'libs/components/slider-btns/next-btn';
import PrevNavigationButton from 'libs/components/slider-btns/prev-btn';
import { StyledBox, StyleWeddingSwipper } from './style';

function SwipeableTextMobileStepper({ cardData }: { cardData: any }) {
  return (
    <StyledBox>
      <StyleWeddingSwipper
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
        style={{
          overflow: 'hidden',
          maxHeight: '25rem',
          borderRadius: '0.8rem',
        }}
        slidesPerView={3}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        breakpoints={{
          899: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          599: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          0: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        }}
      >
        <PrevNavigationButton count={cardData.length} />
        <NextNavigationButton />

        {cardData?.map((item: any) => (
          <SwiperSlide
            style={{
              display: 'flex',
              gap: '2.5rem',
              justifyContent: 'center',
              padding: '0.2rem',
            }}
            key={item.id}
          >
            <a
              href={`/${item.category?.slug}-${item.mastercategory?.slug}/${item?.slug}`}
              key={item.id}
              style={{ width: '100%', padding: '1% 2%' }}
            >
              <Image
                unoptimized
                src={`${process.env.CONTENT_BASE_URL}/${item.image_url}`}
                alt={item.name}
                width={0}
                height={0}
                layout="responsive"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            </a>
          </SwiperSlide>
        ))}
      </StyleWeddingSwipper>
    </StyledBox>
  );
}

export default SwipeableTextMobileStepper;
